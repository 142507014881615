.send-reminder-modal {
  .p-dialog-header {
    .modal-title {
      font-size: 24px !important;
      font-weight: 900 !important;
    }
  }

  &__content {
    .subinfos {
      background: #f6f7fb;
      border-radius: 10px;
      display: flex;
      padding: 10px;
    }
    &__infos {
      align-items: center;
      display: flex;

      ul {
        list-style-type: none;
        padding-left: 15px;
        font-size: 12px;
        margin-bottom: 0px;
      }
      .invoice-amount {
        color: #1f2d3d;
        font-size: 18px;
      }
      img {
        width: 30px;
        height: 30px;
      }
      .pdf-icon {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-size: 10px;
        font-weight: 500;
        margin-left: 10px;
        color: #1f2d3d;
        cursor: pointer;
      }
    }
    &__form {
      display: flex;
      align-items: flex-end;
      margin-top: 3px;
      .emails {
        margin-bottom: 0px !important;
        .input-container:nth-child(n+1) {
          margin-top: 4px !important;
        }
        input {
          width: 100%;
        }
      }
      .submit-button-container {

        .submit-button {
          background: #cf3339;
          border-radius: 5px;
          font-size: 14px;
          line-height: 19px;
          text-align: center;
          font-weight: 800;
          color: #ffffff;
          width: 100%;
          padding: 10px;
          border: 1px solid #cf3339;
          outline: none !important;
        }
      }
    }
    .add-email {
      text-decoration: underline;
      color: #7D83A2;
      font-size: 14px;
      cursor: pointer;
      width: fit-content;
    }
  }

  .form-footer {
    width: 100%;
    padding: 0.5rem;
    .cancel-button {
      background: rgba(0, 0, 0, 0);
      border-radius: 5px;
      font-size: 14px;
      line-height: 19px;
      text-align: center;
      width: 100%;
      padding: 10px;
      color: #7d83a2;
      border: 1px solid #d4dae9;
      outline: none !important;
    }
  }
}
