.total-shipment-count {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 25px;
  color: #7D83A2;
}

.carrier-icon-container {
  height: 75px;
  width: 75px;
  display: flex;
}

.carrier-icon {
  margin: auto;
}

.shipment-status-chip {
  height: 27px;
  background: #CF3339;
  border-radius: 10px;
  text-align: center;
  &-label {
    margin: auto;
    text-align: center;
    font-style: normal;
    font-weight: 800;
    font-size: 14px;
    color: #FFFFFF;
  }
}

.p-sidebar-tracking-mask {
  background: rgba(0, 0, 0, 0) !important;
  backdrop-filter: blur(0px) !important;
}

.tracking-right-sidebar {
  z-index: 2002;
  width: 560px !important;
  overflow: auto;
  &__isHidden{
    z-index: -1;
    width: 0px !important;
  }
}

.test {
  .p-button.p-button-icon-only.p-button-rounded {
    border-radius: 25%;
    height: 2.357rem;
  }
}

.tracking-confirm-dialog {
  .p-dialog-content{
    justify-content: center;
    min-height: 150px;
  }
}

.void-confirm-dialog {
  .p-dialog-content{
    justify-content: center;
    min-height: 150px;
  }
}
