/*********************************GENERAL***********************/
$ship-primary: #CF3339;
$ship-secondary: #E5E9F2;
$ship-secondary-dark: #8492A6;


// grids
$sm: 576px;
$md: 768px;
$lg: 992px;
$xl: 1200px;
$xxl: 1400px;
$xxxl: 1600px;

// forms
$label-color: #7D83A2;
