.customer-toolbar{
  background-color: white !important;
  border: none !important;
}

.customer-button {
  background: #F6F7FB !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 19px !important;
  text-align: center !important;
  border-radius: 8px !important;
  border: #F6F7FB !important;
  /* Black */
  color: #1F2D3D !important;
  box-shadow: none;

  &:disabled{
    background: #F6F7FB !important;
    color: #D4DAE9 !important;
  }

  &:hover {
    opacity: 85% !important;
  }

  &:focus {
    outline: none !important;
    box-shadow: none !important;
  }
}

.page-counter {
  background: #F6F7FB !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 19px !important;
  text-align: center !important;
  border-radius: 8px !important;
  border: #F6F7FB !important;
  /* Black */
  color: #1F2D3D !important;
  box-shadow: none;

  &:disabled{
    background: #F6F7FB !important;
  }

  &:hover {
    opacity: 85% !important;
  }

  &:focus {
    outline: none !important;
    box-shadow: none !important;
  }
}

.add-customer-button {
  background: #CF3339 !important;
  border-radius: 8px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 19px !important;
  text-align: center !important;
  border: #F6F7FB !important;
  box-shadow: none !important;
  color: #FFFFFF !important;

  &:disabled{
    background: #F6F7FB !important;
    color: #D4DAE9 !important;
  }

  &:hover {
    opacity: 85% !important;
  }

  &:focus {
    outline: none !important;
    box-shadow: none !important;
  }
}

.export-logo {
  background: url('../assets/icons/customers/export-bulk.svg')  no-repeat !important;
  height: 21px;
  width: 21px;
  margin-left: 0 !important;
  margin-top: 0 !important;
}

.import-logo {
  background: url('../assets/icons/customers/import-bulk.svg')  no-repeat !important;
  height: 21px;
  width: 21px;
  margin-left: 0 !important;
  margin-top: 0 !important;
}

.filter-logo {
  background: url('../assets/icons/customers/customer-filter.svg')  no-repeat !important;
  height: 16px;
  width: 16px;
  margin-left: 0 !important;
  margin-top: 0 !important;
}

.search-option {
  background: url('../assets/icons/customers/search-option.svg')  no-repeat !important;
  height: 16px;
  width: 16px;
  margin-left: 0 !important;
  margin-top: 0 !important;
}

.filter-tag-input {
  height: 35px;
  background-color: #F6F7FB !important;
  border-radius: 8px !important;
  border: #F6F7FB !important;
  /* Black */
  color: #1F2D3D !important;
  box-shadow: none;

  &:focus {
    outline: none !important;
    box-shadow: none !important;
  }
}

.delete-confirm-dialog {
  .p-dialog-content{
    justify-content: center;
    min-height: 120px;
  }
}

.delete-bulk-confirm-dialog {
  .p-dialog-content{
    justify-content: center;
    min-height: 120px;
  }
}

.total-address-count {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 25px;
  color: #7D83A2;
  padding: 0.5rem 1rem
}
