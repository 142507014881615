.AutoComplete-container {
  width: 654px;
  .input-container {
    img {
      top: 18px;
      z-index: 1;
      left: 10px;
      position: relative;
      cursor: pointer;
    }
    input {
      width: 654px;
      height: 40px;
      background: #f6f7fb;
      border-radius: 10px;
      border-width: 0;
      outline: none;
      position: relative;
      top: -12px;
      padding-left: 40px;
    }
  }

  .customers-description {
    padding: 10px 0px;
    padding-left: 2rem;
    color: #1f2d3d;
    font-size: 16px;
    span {
      font-size: 16px;
      font-weight: 500;
    }
  }
  .results-list {
    position: absolute;
    z-index: 1;
    background: white;
    width: 654px;
    overflow: auto;
    ul {
      list-style-type: none;
      color: #7d83a2;
      padding: 0px;
      margin-bottom: 0;
      li {
        padding: 10px 0px;
        padding-left: 2rem;
        cursor: pointer;
      }
      li:hover {
        background: #f6f7fb;
      }
    }
    .show-more {
      color: #1f2d3d;
      font-weight: 600;
      cursor: pointer;
      padding: 10px 0px;
      padding-left: 2rem;
    }
  }
}
