.invoices-page {
  .invoices-header, .invoices-body {
    padding: 0.75rem 1.25rem;
    background-color: white;
    box-shadow: 0px 15px 50px rgba(60, 75, 92, 0.05);
    border-radius: 10px;
    background: white;
    margin-bottom: 20px;
  }
  .invoices-header {
    .title-container {
      .title {
        font-weight: 900;
        font-size: 36px;
        color: #1f2d3d;
      }
      .account-total {
        font-size: 18px;
        color: #7d83a2;
        span {
          color: #5bca66;
        }
      }
    }

    .button-container {
      button {
        border: 1px solid #cf3339;
        box-sizing: border-box;
        border-radius: 10px;
        width: 162px;
        height: 40px;
        background: white;
        color: #cf3339;
        margin-left: 20px;
        font-weight: 800;
        font-size: 16px;
        line-height: 22px;
      }
      .selected {
        background: #cf3339;
        color: #ffffff;
      }
    }
  }
  .invoices-body {
    color: #7D83A2;
    .p-datatable .p-datatable-thead > tr > th {
      background: white;
      border: 0px;
    }
    .p-datatable .p-datatable-thead > tr > td {
      border: 0px;
      padding: 1.5rem 1rem;
      border-width: 0px;
    }

    .p-datatable .p-datatable-tbody > tr > td {
      border-width: 0;
      padding: 1.5rem 1rem;
      color: #7D83A2;
    }
    .invoiceId {
      color: #1F2D3D !important;
    }

    .p-datatable.p-datatable-striped .p-datatable-tbody > tr:nth-child(odd) {
      background: #F6F7FB;
      td:last-child{
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
      }
      td:first-child{
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
      }
    }
    .p-datatable.p-datatable-striped .p-datatable-tbody > tr:nth-child(even) {
      background: rgba(0,0,0,0);
    }

    .p-datatable.p-datatable-striped .p-datatable-tbody .selected-row {
      background: #1F2D3D !important;

      td {
        color: white;
      }
      td:last-child{
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
      }
      td:first-child{
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
      }
    }
    .files-download {
      img {
        cursor: pointer;
      }
      img:first-child{
        margin-right: 15px;
      }
    }
  }
  .p-paginator{
    justify-content: flex-end;
    .page-number-button {
      background: #F6F7FB;
    border-radius: 5px;
    color: #1F2D3D;
    font-size: 14px;
    padding: 9px 16px;
    }
    .page-of-button {
      padding: 9px;
      color: #7D83A2;
      font-size: 14px;
    }
  }
}

.pagination {

}

.pdf-invoice {
  border-radius: 10px !important;
  background: #1F2D3D !important;
  box-shadow: 0px 15px 50px rgba(60, 75, 92, 0.05) !important;

  .p-dialog-header {
    background: #1F2D3D !important;
    box-shadow: 0px 15px 50px rgba(60, 75, 92, 0.05) !important;
    .modal-title {
      color: #FFFFFF !important;
      h3, h2 {
        color: white !important;
        font-size: 24px !important;
        text-transform: capitalize;
      }
      h2{
        color: white !important;
        font-weight: 900 !important;
      }
      h3 {
        font-weight: 500 !important;
      }
    }
  }

  .p-dialog-content {
    background: #1F2D3D !important;
  }

  .pdf-container {
    overflow: hidden;
    height: 80%;

    .pdf-object-rendering {
      height: 66vh;
      width: 100%;
    }
  }
  .commercial-invoice-resize {
    zoom: 0.5;
  }

  .form-footer {
    width: 100%;
    padding: 0.5rem;
    .cancel-button {
      border: 1px solid white;
      box-sizing: border-box;
      border-radius: 5px;
      width: 100%;
      color: white;
      font-size: 14px;
      padding: 9px;
      background: #1F2D3D !important;
    }
    .submit-button {
      background: #cf3339;
      border-radius: 5px;
      font-size: 14px;
      line-height: 19px;
      text-align: center;
      font-weight: 800;
      color: #ffffff;
      width: 100%;
      padding: 10px;
      border: 1px solid #cf3339;
      outline: none !important;
    }
  }

}
