.disable-account-modal {
  width: 400px !important;
  h1 {
    font-weight: 800;
    font-size: 30px;
    line-height: 41px;
    text-align: center;
    text-transform: capitalize;
    color: #1f2d3d;
  }
  p {
    font-size: 18px;
    line-height: 25px;
    text-align: center;
    color: #7d83a2;
  }
  .disable-footer {
    width: 100%;
    padding: 0.5rem;
    .cancel-button {
      border: 1px solid #d3d3ed;
      box-sizing: border-box;
      border-radius: 5px;
      width: 100%;
      color: #7d83a2;
      font-size: 14px;
      padding: 9px;
      background: white;
    }
    .disable-button {
      background: #cf3339;
      border-radius: 5px;
      font-size: 14px;
      line-height: 19px;
      text-align: center;
      font-weight: 800;
      color: #ffffff;
      width: 100%;
      padding: 10px;
      border: 1px solid #cf3339;
      outline: none !important;
    }
  }
}
