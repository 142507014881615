@import "./_variable.scss";
//Import Icon scss
@import "./theme/assets/scss/icons.scss";

//import App scss
@import "./theme/assets/scss/bootstrap.scss";
@import "./theme/assets/scss/app.scss";

//Import Dark theme
// @import "./assets/scss/bootstrap-dark.scss";
// @import "./assets/scss/app-dark.scss";

// RTL mode
// @import "./assets/scss/bootstrap.scss";
// @import "./assets/scss/app-rtl.scss";

// Form Styles
@import "./styles/formik-formik-styles.scss";

.nav-link {
  cursor: pointer;
}

.dataTables_filter,
.dataTables_paginate {
  float: right;
  .pagination {
    .page-link {
      border-radius: 30px !important;
      margin: 0 3px;
      border: none;
      text-align: center;
      cursor: pointer;
    }
  }
}

.rdw-editor-main {
  border: 1px solid $gray-300;
  height: 239px;
}
.dz-message {
  text-align: center;
  padding: 100px;
}

.react-datepicker-wrapper {
  width: 100% !important;
}

.ReactModal__Overlay {
  z-index: 1001 !important;
}

.rangeslider__fill {
  background-color: $primary !important;
}

.react-bootstrap-table {
  padding-top: 0px !important;
}

.square-switch {
  .react-switch-bg {
    border-radius: 5px !important;
  }
}

input[type="range"] {
  -webkit-appearance: auto !important;
}

// modal styling

.p-component-overlay {
  z-index: 1002 !important;
  background: rgba(31, 45, 61, 0.5);
  backdrop-filter: blur(10px);
  .p-dialog {
    background: #ffffff;
    box-shadow: 0px 15px 50px rgba(60, 75, 92, 0.05);
    border-radius: 10px;
    padding: 15px;
    @media screen and (min-width: $md) {
      width: 730px;
    }
    @media screen and (max-width: $md) {
      width: 100%;
    }
    .p-dialog-title {
      width: 100%;
      .modal-title {
        h2{
          font-weight: 900;
          font-size: 30px;
          line-height: 41px;
          text-transform: capitalize;
          color: #1f2d3d;
        }
      }
    }
    .p-dialog-content {
      padding-top: 10px;
      padding-bottom: 0px;
    }
  }
}
