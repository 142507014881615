@import "./_variable.scss";
@import "~bootstrap/scss/bootstrap";
@import "~bootstrap/scss/buttons";

.ship-primary-custom-button {
  @extend .btn;
  background: $ship-primary;
  color: #fff;
  border-color: $ship-primary;
  font-weight: bold;

  &,
  &:active {
    background-color: $ship-primary;
    border-color: transparent;
    color: #ffffff;
    text-decoration: none;
    box-shadow: none;
  }

  &:focus {
    opacity: 0.8;
    color: #ffffff;
    border-color: transparent;
    box-shadow: none;
    text-decoration: none;
  }

  &:hover {
    opacity: 0.8;
    color: #ffffff;
    border-color: transparent;
    box-shadow: none;
    text-decoration: none;
  }

  &:disabled {
    background: $ship-secondary;
    color: #7d83a2;
    font-weight: bold;
  }
}

.ship-secondary-custom-button {
  @extend .btn;
  background: #ffffff;
  color: $ship-secondary-dark;
  border-color: $ship-secondary-dark;
}

.carousel-indicators li {
  background-color: red;
}

.ship-input.form-control:focus {
  border-color: $ship-secondary;
  box-shadow: 0 0 0 0.2rem $ship-secondary;
}

.ship-input.form-control.is-valid,
.ship-input.form-control.is-invalid {
  background-image: none;
  padding-right: inherit;
}

.ship-input {
  max-width: 333px;
}

.ship-input-nl {
  width: 100%;
}

.ship-input-nl.form-control:focus {
  border-color: $ship-secondary;
  box-shadow: 0 0 0 0.2rem $ship-secondary;
}

.ship-input-nl.form-control.is-valid,
.ship-input-nl.form-control.is-invalid {
  background-image: none;
  padding-right: inherit;
}

.ship-input-error {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 14px;
  color: #cf3339;
}

.ship-input-label {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #7d83a2;
}

.fp-container {
  background: #f8f8f8ad;
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  overflow-x: hidden;
  transition: 0.5s;
  z-index: 2001 !important;
}

.fp-container .fp-spinner {
  color: $ship-primary;
}

.fp-container .fp-spinner-label {
  color: $ship-primary;
  font-weight: bold;
}

.custom-container-padding {
  padding: 50px 50px 50px 50px !important;
}

// Confirm modal React Prime customization
.p-confirm-dialog {
  .p-dialog-header {
    justify-content: center !important;

    span {
      font-style: normal !important;
      font-weight: 600 !important;
      font-size: 30px !important;
      text-align: center !important;
      text-transform: capitalize !important;
      color: #1f2d3d;
    }
  }

  .p-dialog-content {
    span {
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 25px;
      text-align: center;
      color: #7d83a2;
    }
  }

  .p-dialog-footer {
    text-align: center !important;

    .p-confirm-dialog-reject {
      border: 1px solid #d4dae9 !important;
      box-sizing: border-box !important;
      border-radius: 10px !important;
      color: #7d83a2 !important;
      box-shadow: none !important;

      &:focus {
        outline: none !important;
        box-shadow: none !important;
      }

      &:hover {
        opacity: 85% !important;
      }
    }

    .p-confirm-dialog-accept {
      margin-left: 1em !important;
      background: #cf3339 !important;
      border-radius: 10px !important;
      color: #ffffff !important;
      box-shadow: none !important;
      border: none !important;

      &:focus {
        outline: none !important;
        box-shadow: none !important;
      }

      &:hover {
        opacity: 85% !important;
      }
    }
  }
}

.functional-modal-title {
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 33px;
  text-transform: capitalize;
  color: #1F2D3D;
}
