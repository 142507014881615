.shipment {
  font-weight: 500;
}

.shipment-background {
  background-color: rgba(255, 255, 255, 0.85);
}

.shipment-cards-background {
  background-color: #fff;
}

.ship-order,
.item-sx {
  color: #7D83A1 !important;
  font-weight: 500;
}

.date-ship {
  font-size: 16px;
  font-weight: 800;
  line-height: 21, 86px;
}

.ship-progress {
  color: #7D83A1;
  font-weight: 800;
  font-size: 12px;
  line-height: 16, 39px;
}

.ship-user-icon {
  width: 30px;
  height: 30px;
  margin: 0 70px 0px 20px;
}

.ship-eye-icon {
  width: 18px;
  height: 13px;
  margin: 4px;
}

.item-sx {
  font-weight: 500;
}

.item-dx {
  text-align: right;
  font-weight: 800;
}

.ship-row {
  font-size: 12px;
  line-height: 16, 39px;
  font-weight: 500;
  margin: 5px;
  background-color: #fff;
  padding-left: 15px;
  padding-bottom: 100px;
  height: 68vh;
}

.ship-row.title {
  height: auto;
}

.footer {
  margin-top: 1000px;
}

.shipment .table td {
  border: 0px !important
}

.ship-Box {
  margin-right: 5px;
  width: 20px;
  height: 20px;
  left: calc(50% - 20px/2 - 289px);
  top: calc(50% - 20px/2 - 58px);
  border: 1px solid #D4DAE9;
  border-radius: 5px;
}

.ship-hr {
  margin: 0px;
  width: 75%;
  height: 5px;
  border-width: 0;
  color: gray;
  background-color: #CF3339;
  border-radius: 3px;
}

.ship-head {
  box-shadow: 0px 15px 50px rgba(60, 75, 92, 0.05);
  border-radius: 10px;
  margin-bottom: 20px;
  height: 110px;
  vertical-align: middle;
}

.ship-total {
  font-size: 24px !important;
  line-height: 24px !important;
  font-weight: 800 !important;
  text-transform: capitalize !important;
}

.ship-info {
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 16px !important;
  line-height: 24px !important;
  color: #D4DAE9 !important;
  margin: 5px 20px 30px;
}

.shipment .red {
  color: #CF3339 !important;
}

.shipment .black {
  color: #000 !important;
}

.shipment .gray {
  color: #7D83A2 !important;
}

.shipment .white {
 color: #fff !important;
}

.shipment .sx {
  text-align: left;
}

.shipment .dx {
  text-align: right;
}

.shipment .ship-btn {
  width: 90%;
  height: 50px;
  box-shadow: 2px 4px 15px #1F2D3D;
}

.shipment .borderC {
  box-shadow: 0px 15px 50px rgb(60 75 92 / 5%);
  border-radius: 10px;
  background-color: rgb(255, 255, 253);
}

.shipment .mrg-5 {
  margin-right: 5px !important;
}

 .ri-luggage-cart-lines {
  background-image: url('../theme/assets/images/luggage-cart-filled.svg');
  background-repeat: no-repeat;
  height: 20px;
}

.ri-luggage-cart-lines::before {
  background-image: url('../theme/assets/images/luggage-cart-filled.svg');
  background-repeat: no-repeat;
}
li.mm-active .ri-luggage-cart-lines {
  background-image: url('../theme/assets/images/luggage-cart-line.svg');
  background-repeat: no-repeat;
  height: 20px;
}

.shipment .mm-active .active {
  border-left: 3px #CF3339 solid;
  border-radius: 2px;
}

.shipment .ship-btn-menu {
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 24px;
  padding: 13px 32px;
  text-align: center;
  text-decoration: none;
  margin-left: 20px;
  transition-duration: 0.1s;
  cursor: pointer;
  color: #7D83A1;
  border-radius: 5px;
  width: 100%;
  border: 0px;
}

.shipment .ship-btn-menu:hover {
  background-color: #CF3339;
  color: white;
}

.shipment .shit-btn-edit {
  width: 65px;
}

.shipment .ship-search .form-control {
  border: none;
  height: 40px;
  padding-left: 40px;
  padding-right: 20px;
  background-color: #f1f5f7;
  box-shadow: none;
  border-radius: 10px;
}

.shipment .ship-search span {
  position: absolute;
  z-index: 10;
  font-size: 16px;
  line-height: 38px;
  left: 13px;
  top: 0;
  color: #74788d;
}

.shipment .ship-btn-search {
  width: 150px;
  height: 40px;
  margin-left: 10px;
  border-radius: 10px;
}

.fnt12 {
  font-size: 12px !important;
}
.fnt18 {
  font-size: 18px !important;
}
.fnt20 {
  font-size: 20px !important;
}
.fnt30 {
  font-size: 30px !important;
}
.ship-card {
  width: 100%;
  padding: 10px;
}

.ship-box-search {
  margin: 30px;
  padding: 10px;
}

.mrg-tb20 {
  margin-bottom: 20px;
}

.ship-form {
  width: 100%;
}

.ship-inputs, option {
  width: 100%;
  height: 40px;
  border: 1px solid #D4DAE9;
  box-sizing: border-box;
  border-radius: 10px;
  vertical-align: bottom;
  font-size: 14px !important;
  color: #3C4858;
  padding: 6px;
  font-weight: 500 !important;
}

.lrg {
  width: 440px;
}

.ship-btn-manage {
  border-width: 0px;
  height: 35px;
  width: 150px;
  border-radius: 10px;
  background-color: #F6F7FB;
  font-weight: 500;
  font-size: 14px;
  text-align: center;
  line-height: 19px;
  color: #7D83A2;
}

.packaging-detail-container {
  background: #FFFFFF;
  box-shadow: 0px 15px 50px rgba(78, 91, 124, 0.1);
  border-radius: 10px;
  padding: 20px;
  height: auto;
}

.ship-btn-gray {
  border: 1px solid #D4DAE9;
  box-sizing: border-box;
  border-radius: 10px;
  height: 40px;
  background-color: #fff;
}

.ship-btn-red {
  height: 40px;
  background: #CF3339;
  border-radius: 10px;
  color: #FFFFFF;
  border-color: #CF3339 !important;
  border: 0px;
  font-size: 20px;
}

.ship-card-carrier,
.ship-card-disabled-carrier {

  width: 100px;
  height: 100px;
  background: #FFFFFF;

  box-sizing: border-box;
  box-shadow: 0px 15px 50px #FFFFFF;
  border-radius: 10px;
  margin: 10px;
}

.activeC,
.ship-card-carrier:hover {
  background: url("../assets/icons/shipment/iconOK.svg") no-repeat top right;
  border: 1px solid #23CA8E;
  opacity: 1;
}

.ship-inputs option:hover {
  background-color: whitesmoke;
  color: #7D83A2;
}

.ship-box-w {
  width: 100% !important;
  background: transparent;
}
.ship-column {
  overflow-y: scroll;
  height: 100%;
}

.ship-column-fixed {
  height: 68vh;
}

.overlaygray {
  background-color: fff;
  z-index: 999999;
  cursor: pointer;
  opacity: 0.3;
}

.tracker {
  width: 50;
  height: 4px;
  margin: 15px auto;
  background-color: #F6F7FB;
  border-radius: 4px;
  box-shadow: inset 0 0 5px #ccc;
}

.progressInTracker {
  height: 4px;
  background: red;
  border-radius: 4px;
}

.ship-list {
  margin: 10px 0 0 0;
  color: #7D83A2;
  list-style: none;
  text-align: left;
  padding: 0;

}

.ship-list.result {
  text-align: right;
}

.black {
  color: #1F2D3D !important;
}

.additional-info-label {
  font-weight: 400;
  font-size: 14px;
  color: #1F2D3D;
  line-height: 24px;
}

.bi-info-circle.packaging-details, .bi-info-circle.additional-information  {
  background-image: url('../assets/icons/shipment/info.svg') !important;
  background-repeat: no-repeat;
  height: 15px;
  width: 20px;
  left: 120px;
  position: absolute;
}

.bi-info-circle.commercial-invoice {
  background-image: url('../assets/icons/shipment/info.svg') !important;
  background-repeat: no-repeat;
  height: 15px;
  width: 20px;
  position: absolute;
}

.fa {
  width: 28px;
  text-align: center;
  margin: 15px 2px;
}

.fa:hover {
  opacity: 0.7;
}

.fa-more {
  background-image: url('../assets/icons/shipment/icon-more.svg') !important;
  background-repeat: no-repeat;
  margin-bottom: 0px;
  margin-top: 0px;
  height: 25px;
  width: 25px;
}

.fa-less {
  background-image: url('../assets/icons/shipment/icon-less.svg') !important;
  background-repeat: no-repeat;
  margin-bottom: 0px;
  margin-top: 0px;
  height: 25px;
  width: 25px;
}

.fa-times-circle {
  color: #CF3339;
}

.fa-search {
  color: #000000;
  padding-left: 15px;
}

.ship-switch {
  position: relative;
  display: inline-block;
  width: 48px !important;
  height: 24px;
}

.ship-column-detail {
  padding: 0px;
}

.shipment-package-card {
  background-color: #F6F7FB;
  border-radius: 10px;
  padding-bottom: 0.5em;;
}

.quote-error-list {
  list-style-type:none;
}

.ship-switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.ship-slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ced4da;
  -webkit-transition: .4s;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
}

.ship-slider:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .ship-slider, .ship-box-w input:checked {
  background-color: #CF3339 !important;
}

input:focus + .ship-slider {
  box-shadow: 0 0 1px #b6bfc8;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
}

input:checked + .ship-slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.ship-slider.ship-round {
  border-radius: 10px;
}

.ship-slider.ship-round:before {
  border-radius: 50%;
}
.p-checkbox .p-checkbox-box.p-highlight{
  border-color: #CF3339 !important;
    background: #CF3339 !important;
}
.head-red{
  background: #CF3339 !important;
  color: #fff !important;
}
.ship-border-w{ border: #fff 1px solid; border-radius: 10px; height: 40px; width: 120px;}

.btn-outline-secondary-ship{
background: #FFFFFF;
border: 1px solid #1F2D3D;
box-sizing: border-box;
border-radius: 10px;
font-weight: 500;
font-size: 20px;
line-height: 24px;
text-align: center;
color: #1F2D3D;
margin: 5px;
height: 50px;
width: 440px;
}

.shipment .btn-outline-secondary-ship:hover {
  background: #F6F7FB;
  color: #7D83A1;
  border: 0px ;
}

.shipment .btn-outline-secondary-ship:disabled {
  background: #F6F7FB;
  color: #7D83A1;
  border: 0px ;
}

.btn-outline-secondary-ship:visited {
  background: #F6F7FB;
  color: #7D83A1;
  border: 0px ;
}
.ship-card,.p-card.p-component.col-4.text-center {
  box-shadow: none;
}
.ship-bg-white{background-color: #fff;}
.w800{font-weight: 800;}
.w400{font-weight: 400;}
.ti-trk{ font-size: 16px;  font-size: 16px;  line-height: 22px; color: #7D83A2 ;   padding: 1px !important;}
.data-trk{  font-size: 16px;   color: #1F2D3D; padding: 1px !important;}
.btn-outline-secondary:hover{color: #fff !important;}
.btn {border-radius: 9px !important;}
.p-menu .p-menuitem-link{color:#D3D3ED !important}
.p-menu .p-menuitem-link:hover{color:#D3D3ED !important}
#pr_id_2_header{
  font-weight: 650;
font-size: 30px;
line-height: 40px;
text-align: center;
text-transform: capitalize;
color: #1F2D3D;
margin-bottom: 0px;
width: 100%;
text-align: center;

}
.shipment #pr_id_2_content{
font-size: 18px;
line-height: 25px;
text-align: center;
color: #7D83A2;
width: 300px;
margin-left: 15%;
}
.p-dialog-header-close-icon{color:#fff}
hr.new4 {
  border: 2px solid #CF3339;
  width:22px;
  border-radius: 2px;
}
.p-dialog-header{padding-bottom: 0px !important; }
#popup_menu{
  top: 1577.5px !important;
  left: 451.656px !important;
}
.p-menu .p-menuitem-link .p-menuitem-text {
  color: #D3D3ED !important;
}
.p-menu .p-menuitem-link .p-menuitem-text :hover{
 color: #1F2D3D !important;
 font-family: Avenir;
font-style: normal;
font-weight: 800;
font-size: 14px;
line-height: 24px;
text-transform: capitalize;
}
.p-menu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
 color: #1F2D3D !important;
 font-style: normal;
 font-weight: 800;
 font-size: 14px;
 line-height: 24px;
 text-transform: capitalize;
}
.ship-popup-email{width: 533; height: 499; }

 #pr_id_4{text-align: center !important;}
.ship-title-popup{
 font-weight: 500;
font-size: 12px;
line-height: 16px;
text-transform: uppercase;
color: #7D83A1;
 }

 .shipment .p-selectbutton .p-button.p-highlight {
  background: #CF3339;
  border-color: #fff;
  color: #ffffff;
}
.shipment .p-selectbutton .p-button.p-highlight:hover {
  background: #ced4da;
  border-color: #ced4da;
  color: #495057; }
  .shipment .p-field-checkbox, .p-field-radiobutton {
   width: 70px; height: 40px;  border: 1px solid #ced4da;  border-radius: 45px;
   float: left;
   margin-right: 10px;
}
.shipment .p-radiobutton{padding-left: 10px; padding-top: 4px;}
.shipment .p-radiobutton .p-radiobutton-box .p-radiobutton-icon {
  width: 12px;
  height: 12px;
  transition-duration: 0.2s;
  background-color: #CF3339;
}
.shipment .p-field-checkbox > label, .p-field-radiobutton > label {
  margin-left: 10px;
  line-height: 1;
  padding: 7px 5px 0 5px;
}
.shipment .p-radiobutton .p-radiobutton-box.p-highlight {
   border-color: #fff;  background: #fff;
}
.shipment .p-radiobutton-focused{border-color: #fff;  background: #fff;}
.shipment .p-radiobutton .p-radiobutton-box:not(.p-disabled).p-focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #fff !important;
  border-color: #fff !important; }
  .shipment .p-radiobutton .p-radiobutton-box:not(.p-disabled):not(.p-highlight):hover {
    border-color: #ced4da !important;
}
.p-radiobutton .p-radiobutton-box {height: 15px !important;}
.ship-switch-big {
  position: relative;
  display: inline-block;
  width: 67px !important;
  height: 24px;

}
.shipment input:checked + .s-big:before {
  -webkit-transform: translateX(40px);
  -ms-transform: translateX(40px);
  transform: translateX(40px);
}
.shipment .p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
  background: #CF3339 !important;
}
.shipment .p-inputswitch {
  width: 45px !important;
  height: 23px !important;
}
.shipment .s-big{left: -1px;}
.admin .row{margin-bottom: 4px;}
.shipment.admin .ship-row{padding: 26px 0 15px 0;}
.page-content.shipment{padding:90px 25px !important}
.ship-switch-big .ship-slider:before {
  height: 20px;
  width: 20px;
  bottom: 2px;
}
.shipment .mx60{width: 66%;}

hr {
  margin: 0px;
  border: none;
  height: 1px;
}
