.on-boarding-section {
    padding: 13em 0;
}

.on-boarding-login-section {
    padding-top: 5em;
}

.forgot-password {
    font-weight: 500;
    font-size: 16px;
    color: #7D83A2;
}

.sign-up-section {
    padding-top: 15em;
}
.sign-up-label {
    font-size: 14px;
    color: #7D83A2;
}

.container-onboarding-padding{
    padding-top: 15px;
    padding-bottom: 15px;
}

.custom-input-margin {
    margin-left: 25px;
}

.info-icon {
    margin-top: 9px;
    margin-left: 5px;
}
