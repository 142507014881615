.admin-right-sidebar {
  z-index: 2002;
  width: 371px !important;
  overflow: auto;
  &__isHidden {
    z-index: -1;
    width: 0px !important;
  }
  .account-disabled-button {
    width: 100%;
    font-weight: 800;
    font-size: 14px;
    line-height: 19px;
    text-align: center;
    color: #ffffff;
    background: #CF3339;
    padding: 10px;
    margin-bottom: 25px;
  }
  .container {
    min-height: calc(100vh - 140px);
    overflow-y: auto;
    margin-bottom: 45px;
    &_disabled {
      opacity: 0.5;
      min-height: calc(100vh - 215px);
    }

    .icon-container {
      text-align: center;
      padding-bottom: 40px;
    }
    .username {
      font-weight: 900;
      font-size: 30px;
      line-height: 41px;
      text-align: center;
      color: #1f2d3d;
      font-family: Avenir;
      margin-bottom: 20px;
    }
    .company-infos {
      list-style-type: none;
      padding-left: 2rem;
      margin-bottom: 40px;
      font-size: 16px;
      line-height: 22px;
      text-transform: capitalize;
      color: #7d83a2;
      li {
        display: flex;
        padding-bottom: 10px;
      }
      img {
        margin-right: 10px;
        width: 13px;
        height: 13px;
        margin-top: 5px;
      }
    }

    .invoicesTableContainer {
      .p-datatable .p-datatable-thead > tr > th {
        background: white !important;
        border: 0px;
        color: #7d83a1;
        font-weight: 500;
        font-size: 16px;
      }

      .p-datatable > th {
        background: white !important;
      }
      .p-datatable .p-datatable-thead > tr > th:last-child {
        width: 100px;
      }
      .p-datatable .p-datatable-tbody > tr > td {
        border-width: 0;
        padding: 1rem 1rem;
        color: #7D83A2;
      }
      .invoiceId {
        color: #1F2D3D;
      }
      .p-datatable.p-datatable-striped .p-datatable-tbody > tr:nth-child(odd) {
        background: #F6F7FB;
        td:last-child{
          border-top-right-radius: 10px;
          border-bottom-right-radius: 10px;
        }
        td:first-child{
          border-top-left-radius: 10px;
          border-bottom-left-radius: 10px;
        }
      }
      .p-datatable.p-datatable-striped .p-datatable-tbody > tr:nth-child(even) {
        background: rgba(0,0,0,0);
      }

      .invoice-status {
        font-size: 12px;
        line-height: 14px;
        text-align: center;
        border-radius: 5px;
        width: 65px;
        padding: 5px;
        &_active, &_CREATED {
          background: #cf3339;
          color: white;
        }
        &_past, &_PAID {
          background: #1F2D3D;
          color: white;
        }
        &_disabled, &_CANCELLED {
          background: #D4DAE9;
          color: #7D83A1;
        }
      }
    }
  }
  .sidebar-footer {
      width: 100%;
      padding: 0.5rem;
      .delete-button {
        border: 1px solid #d3d3ed;
        box-sizing: border-box;
        border-radius: 5px;
        width: 100%;
        color: #7d83a2;
        font-size: 14px;
        padding: 9px;
        background: white;
      }
      .disable-button {
        background: #cf3339;
        border-radius: 5px;
        font-size: 14px;
        line-height: 19px;
        text-align: center;
        font-weight: 800;
        color: #ffffff;
        width: 100%;
        padding: 10px;
        border: 1px solid #cf3339;
        outline: none !important;
      }
      .enable-button {
        background: #5BCA66;
        border-radius: 5px;
        font-size: 14px;
        line-height: 19px;
        text-align: center;
        font-weight: 800;
        color: #ffffff;
        width: 100%;
        padding: 10px;
        border: 1px solid #5BCA66;
        outline: none !important;
      }
  }
}

.p-sidebar-mask {
  background: rgba(0, 0, 0, 0) !important;
  backdrop-filter: blur(0px) !important;
}
