.datatable-crud-demo .table-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}


.datatable-crud-demo .confirmation-content {
  display: flex;
  align-items: center;
  justify-content: center;
}

.datatable-crud-demo {
  .p-datatable {
    .p-paginator {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
    .p-datatable-thead {

      .p-checkbox-box {
        outline: none !important;
        box-shadow: none !important;
        border-radius: 3px;
        border: 1px solid #D4DAE9 !important;

        &:checked {
          outline: none !important;
          box-shadow: none !important;

          &:hover {
            outline: none !important;
            box-shadow: none !important;
            opacity: 85% !important;
            border-color: #CF3339 !important;
            background: #CF3339;
          }
        }

        &:hover {
          outline: none !important;
          box-shadow: none !important;
          opacity: 85% !important;
          border-color: #CF3339 !important;
          background: #ffffff;
        }
      }
    }

    .p-datatable-tbody {
      tr:nth-child(even) {
        background: #F6F7FB !important;;
        border-radius: 10px;
      }

      tr {
        border-radius: 10px;

        .p-highlight {
          color: #495057;
          background: #CF3339;
          border-radius: 3px;
          box-shadow: none !important;
          border-color: #CF3339 !important;

          &:hover {
            outline: none !important;
            box-shadow: none !important;
            opacity: 85% !important;
            border-color: #CF3339 !important;
            background: #CF3339;
          }

          &:focus {
            outline: none !important;
            box-shadow: none !important;
            border-color: #CF3339 !important;
            background: #CF3339;
          }
        }
      }

      .p-checkbox-box {
        outline: none !important;
        box-shadow: none !important;
        border-radius: 3px;
        border: 1px solid #D4DAE9 !important;

        &:checked {
          outline: none !important;
          box-shadow: none !important;

          &:hover {
            outline: none !important;
            box-shadow: none !important;
            opacity: 85% !important;
            border-color: #CF3339 !important;
            background: #CF3339;
          }
        }

        &:hover {
          outline: none !important;
          box-shadow: none !important;
          opacity: 85% !important;
          border-color: #CF3339 !important;
          background: #ffffff;
        }
      }

      tr.p-highlight {
        border-radius: 10px;
        color: #ffffff;
        background: #1F2D3D !important;
      }
    }
  }
}
